import React from "react";
import { Grid, Row } from "../components/grid";
import posed from "react-pose";

function Main() {
  const Letter = posed.div({
    hoverable: true,
    draggable: true,
    init: { scale: 1 },
    hover: { scale: 1.2 }
  });

  return (
    <>
      <Grid>
        <Row>
          <Letter>R</Letter>
          <Letter>A</Letter>
          <Letter>U</Letter>
        </Row>
        <Row>
          <Letter>H</Letter>
          <Letter>U</Letter>
          <Letter>T</Letter>
        </Row>
      </Grid>
    </>
  );
}

export default Main;
